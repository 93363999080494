import React from 'react'

export default function RefundPolicy() {
    return <>
        <div className="privacy-policy refund">
            <div className="top">
                <h1> سياسة الاسترجاع  </h1>
            </div>
            <div className="container">
                <h5>
                    CANCELLATION POLICY
                </h5>
                <p>
                    -the educational merchant must add in his website (“Fawry responsibility is limited to payment collection. For any related issue please contact us” )
                </p>
                <h5>
                    1. GENERAL
                </h5>
                <p>
                    [COMPANY NAME] is committed to providing exceptional service in a timely manner Unfortunately,
                </p>
                <p>
                    when customer cancels without giving enough notice, it prevents another customer from being served. No shows and late cancellation have an impact on service quality while punishing customers who may show up earlier. For these reasons. [COMPANY NAME] has implemented a cancellation policy that will be strictly observed.
                </p>
                <h5>
                    2. FULL PAYMENT
                </h5>
                <p>
                    Your registration is complete when we receive your full payment. Payments can be online or in person. We do not reserve [SPECIFY] without payment.
                </p>
                <p>
                    An online confirmation email will be sent to you at the time of registration and payment. This email serves as confirmation of your registration.
                </p>
                <h5>
                    3. CANCELLATION REQUEST
                </h5>
                <p>
                    Cancellation requests may be submitted by phone, email, online or in person. Please note that refunds will be processed in the original form of payment. If you have any questions or concerns about our cancellation policy, please contact us at (PHONE NUMBER]
                </p>
                <h5>
                    4. REFUND POLICY
                </h5>
                <p>
                    The refund request must not exceed 14 days from the date of payment. If the refund request is accepted the full paid amount will be recovered to your account in 14 working days.
                </p>
            </div>
        </div>
    </>
}
