import React, { useEffect, useState } from "react";
import "../properties/properties.css";
import { FaCheck } from "react-icons/fa6";
import { Link } from "react-router-dom";
import prop1 from "../../../../assets/gharabawy/about1.gif";
import prop2 from "../../../../assets/gharabawy/about2.gif";
import prop3 from "../../../../assets/gharabawy/about3.gif";
export const Properties = () => {
  return (
    <>
      <div className="props">
        <div className="props-content">
          <div data-aos="fade-left" data-aos-duration="1000" className="ap">
            <img src={prop1} alt="About" />
            <h2> الشرح </h2>
            <p>
              هينزلك فيديوهات شرح المنهج اول بأول وكمان ممكن تشوف الفيديو ٤ مرات
            </p>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" className="ap">
            <img src={prop2} alt="About" />
            <h2> مذكرات المنهج</h2>
            <p>
              عشان تقدر تتابع الشرح هينزلك لكل باب المذكره الخاصه بيه pdf تقدر تذاكر منها من خلال الموبايل او تطبعها
            </p>
          </div>
          <div data-aos="fade-right" data-aos-duration="1000" className="ap last">
            <img src={prop3} alt="About" />
            <h2>امتحانات  إلكترونية</h2>
            <p>
              كل اسبوع امتحان لمتابعتك ومتابعه مستواك
            </p>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" className="ap last">
            <img src={prop3} alt="About" />
            <h2>  تقرير دوري بالمستوى </h2>
            <p>
              هيتم متابعتك وحل اي مشكله تقف معاك من خلال فريق عمل الدكتور ومتابعه مع ولي أمرك بالمستوى والدرجات
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
