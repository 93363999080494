import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import config from '../../../config';
import { auth } from '../../../Context/AuthStore';
import axios from 'axios';
import Swal from 'sweetalert2';

export default function ExamsRowBox({data , courseId , refresh , type}) {

    const {userAuth} = useContext(auth)

    const navigate = useNavigate()

    function getHumanDate(d) {
        const date = new Date(d);
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('ar', options);
    }

    const deleteExam = (id) => {
        Swal.fire({
            title: `هل انت متاكد من حذف الامتحان : ${data.name}`,
            icon: "warning",
            showCancelButton : true ,
            cancelButtonText : 'لا شكرا' ,
            showConfirmButton : true,
            confirmButtonText : 'حذف',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                axios.delete(`${config.basURL}dashboard/${type}-exam-overview/${courseId}` , {
                    headers: {
                        'Authorization': config.ApiKey,
                        'auth': `Bearer ${userAuth}`,
                    },
                    data: {id_exam: id}
                }).then(res => {
                    refresh()
                    Swal.fire({
                        text: 'تم الحذف بنجاح' ,
                        icon: 'success'
                    })
                })
            }
        })
    }

    return <>
        <div className="exams-row-box">
            <div className="row-header">
                <ul className='right'>
                </ul>
                <div className="left controlles">
                    <Link className='students-mark' to={`/dashboard/exam-results/${data.id}`}>
                        <i className="fa-solid fa-users"></i>
                        درجات الطلاب
                    </Link>
                    <Link to={`/dashboard/questions-answers/${data.id}/exam`} className='add-questions'>
                        <i className="fa-solid fa-question"></i>
                        اضافة الاسئلة
                    </Link>
                    <button className='settings' onClick={() => navigate(`/dashboard/exam-update/${data.id}/${courseId}/${data.name}/${type}`)}>
                        <i className="fa-solid fa-gear"></i>
                    </button>
                    <button className='delete' onClick={() => deleteExam(data.id)}>
                        <i className="fa-solid fa-trash-can"></i>
                    </button>
                </div>
            </div>
            <div className="box-body">
                <div className="right">
                    <div className='name'>
                        <p className='m-0'>
                            أسم الامتحان
                        </p>
                        {data.name}
                    </div>
                    <div className='image-container'>
                        <p className='m-0'>
                            الوصف
                        </p>
                        <div className='descritption'>
                            {data.description}
                        </div>
                    </div>
                </div>
                <div className="middle">
                    <div className='start-date'>
                        <p className='m-0'>
                            تاريخ الانشاء
                        </p>
                        {getHumanDate(data.created)}
                    </div>
                    <div className="questions-count">
                        <p className='m-0'>
                            عدد الاسئلة
                        </p>
                        {data.exam_questions_count} سؤال
                    </div>
                    
                </div>
                <div className="left">
                <div className='enter-count'>
                            <p className="m-0">
                            عدد مرات الدخول
                            </p>
                            {data.is_many_times ? 'اكثر من مره' : 'مره واحده'}
                        </div>
                        <div className="questions-count">
                        <p className='m-0'>
                            مده الامتحان
                        </p>
                        {data.exam_minutes} دقائق
                    </div>
                    <div>
                        <p className='m-0'>
                            حاله الامتحان
                        </p>
                        {
                            data.pending ? ' غير مفعل ' : ' مفعل '
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}
