import React, { useContext, useEffect, useState } from 'react'
import incom from '../../../assets/incom.svg';
import avatar from '../../../assets/avatar.svg';
import Invoice from '../../../Components/Dashboard/Invoices/Invoice';
import axios from 'axios';
import { auth } from "../../../Context/AuthStore"
import config from '../../../config'
import Placeholder from '../../../Components/Used/Placeholder'
import Validator from '../../../Validator';
import Swal from 'sweetalert2';

export default function SubscriptionsInvoices() {

    const { userAuth } = useContext(auth)
    const [invoices, setinvoices] = useState(false)

    const [subscriptionsCount, setsubscriptionsCount] = useState(0)

    const [totalMony, settotalMony] = useState(0)

    const [currentPage, setCurrentPage] = useState(1)
    const [bySearch, setbySearch] = useState(false)
    const [nextPage, setNextPage] = useState(false)

    const [searchBy, setsearchBy] = useState({})

    const [newInvoice, setnewInvoice] = useState({})

    const updateNewInvoiceData = (e) => {
        let data = {...newInvoice}
        data[e.target.name] = e.target.value;
        setnewInvoice(data)
    }

    const updateSearchBy = (e) => {
        let data = { ...searchBy }
        data[e.target.name] = e.target.value;
        setsearchBy(data)
    }

    const getInvoices = (search = false) => {
        setinvoices(false)
        let searchParam = ''
        if (search) {
            searchParam = `&${Object.keys(search)[0]}=${search[Object.keys(search)[0]]}`
        }

        axios.get(`${config.basURL}dashboard/invoice-list?page=${currentPage}${search ? searchParam : ''}`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            console.log(res.data)
            settotalMony(res.data.results[0].total_money)
            let next = res.data.next ? true : false;
            setNextPage(next)
            setsubscriptionsCount(res.data.count)
            setinvoices(res.data.results)
        }).catch(err => {
            console.log(err)
        })
    }

    const search = (e) => {
        e.preventDefault()

        let validation = new Validator;
        // check if choose search by or not 
        validation.setKey('search_by').setValue(searchBy.search_by).required()

        if (validation.haveError) {
            Swal.fire({
                text: 'choose search by ',
                icon: 'error'
            })
            return
        }
        // check if enter value or not 
        validation.setKey('value').setValue(searchBy.value).required()
        if (validation.haveError) {
            Swal.fire({
                text: 'enter value',
                icon: 'error'
            })
            return
        }
        let data = {}
        data[searchBy.search_by] = searchBy.value
        setCurrentPage(1)
        getInvoices(data)
    }


    // student_user_username
    // course__name
    // reference_number


    useEffect(() => {
        if (userAuth) {
            getInvoices()
        }
    }, [userAuth])

    return <>
        <div className="container-fluid dashboard-subscriptions-invoices">
            <h1>
                الأشتراكات و الفواتير
            </h1>
            <div className='totla-incom-subscription-count'>
                <div className='total-incom'>
                    <div className='total'>
                        <h6>
                            اجمالي الارباح
                        </h6>
                        <p className='m-0'>
                            <span>{totalMony}</span> جنيه
                        </p>
                    </div>
                    <div className="image">
                        <img src={incom} alt="" className='w-100 h-100' />
                    </div>
                </div>
                <div className="subscription-count">
                    <div className="count">
                        <h6>
                            عدد المشتركين
                        </h6>
                        <p className='m-0'>
                            {subscriptionsCount}
                        </p>
                    </div>
                    <div className='image'>
                        <img src={avatar} alt="" className='w-100 h-100' />
                    </div>
                </div>
            </div>
            <h2 className='page-title'>
                الفواتير
            </h2>
            <div className="filter-invoices">
                <form action="" onSubmit={search} className='d-flex align-items-center justify-content-around w-100 '>
                    <div className="group d-flex flex-column" style={{width:'30%' , marginLeft: '10px'}}>
                        <label htmlFor="search_by">
                            البحث باستخدام
                        </label>
                        <select name="search_by" id="search_by" onChange={updateSearchBy}>
                            <option value=""> اختار طريقه البحث </option>
                            <option value="student__user__username"> رقم هاتف الطالب </option>
                            <option value="course__name"> اسم الكورس </option>
                            <option value="reference_number"> reference number </option>
                        </select>
                    </div>
                    <div className="group" style={{width: '30%', marginLeft: '10px'}}>
                        <input type="text" name='value' onChange={updateSearchBy} />
                    </div>
                    <button className=''> بحث </button>
                </form>
                {/* <div className='filter'>
                    <button>
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </button>
                    <ul className='invoice-type'>
                        <li>
                            Phone cach
                        </li>
                        <li>
                            Cach
                        </li>
                        <li>
                            Visa
                        </li>
                    </ul>
                </div> */}
                {/* <div className='date-filter'>
                    <div className="from">
                        <label htmlFor="from">
                            من تاريخ
                        </label>
                        <input type="date" id='from' />
                    </div>
                    <div className="separate"></div>
                    <div className="to">
                        <label htmlFor="to">
                            الى تاريخ
                        </label>
                        <input type="date" id='to' />
                    </div>
                </div> */}
            </div>
            <div className='invoices'>

                <div className="dashboard-invoice-container">
                    {
                        invoices ? (
                            invoices.length > 0 ? invoices.map(invoice => <Invoice key={invoice.id} data={invoice} />) : <p className='py-3 text-center'> لا يوجد فواتير حتي الان </p>
                        ) : <Placeholder />
                    }

                </div>
                <div className="pagination-btn">
                    <button onClick={() => setCurrentPage((prev) => prev - 1)} className={`prev ${currentPage > 1 ? '' : 'disable'}`} disabled={currentPage > 1 ? false : true}> السابق </button>
                    <button className={`next ${nextPage ? '' : 'disable'}`} disabled={nextPage ? false : true} onClick={() => setCurrentPage((prev) => prev + 1)}> التالي </button>
                </div>
            </div>
        </div>
    </>
}
