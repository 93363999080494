import { createContext, useEffect, useState } from "react";

export let darkMode = createContext(false) ;

export default function DarkModeProvider(props) {

    const [darkTheme, setDarkTheme] = useState(false)

    const ToggleDarkTheme = () => {
        if(darkTheme){
            setDarkTheme(false)
            document.body.classList.remove('dark')
            localStorage.setItem('theme' , 'light')
        }else{
            setDarkTheme(true)
            document.body.classList.add('dark')
            localStorage.setItem('theme' , 'dark')
        }
    }


    useEffect(()=>{
            if(localStorage.getItem('theme') === null){
                localStorage.setItem('theme' , 'light')
            }

            if(localStorage.getItem('theme') === 'light' ){
                setDarkTheme(false)
            }else {
                setDarkTheme(true)
                document.body.classList.add('dark')
            }
    } , [])

    return <darkMode.Provider value={{ToggleDarkTheme , setDarkTheme , darkTheme}}>
        {props.children}
    </darkMode.Provider>
}