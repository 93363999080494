import axios from 'axios'
import React, { useContext, useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import config from '../config'
import { auth } from '../Context/AuthStore'
import Placeholder from '../Components/Used/Placeholder'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

export default function CodeGenerate() {

    const { userAuth } = useContext(auth)
    const { id } = useParams()
    const navigate = useNavigate()
    const codeInit = useRef(false)

    const create = () => {
        axios.post(`${config.basURL}subscription/create-invoice`, {
            course_id: id
        }, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            // setcode(res.data.referenceNumber)
            // console.log(res.data)
            navigate(`/invoice/${res.data.referenceNumber}/${res.data.id_invoice}`)
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (!codeInit.current) {
            codeInit.current = true
            create()
        }
    }, [])

    return <>
        <div className='realod d-flex justify-content-center align-items-center'>
            <Placeholder />
        </div>
    </>
}
