import React from "react";
import lightLogo from "../assets/gharabawy/logo.svg";
import companyLogo from "../assets/company-logo.png";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="contact-section">
            <div className="footer-logo">
              <img className="w-100 h-100" alt="" src={lightLogo} />
            </div>
            <h4 className="text-center">تواصل معنا عبر واتساب</h4>
            <div className="row py-2 w-100 ">
              {/* <div className='email col-lg-3 col-12 text-start'>
                        AhmedFadl@gmail.com
                        mahmoud-elgharabawy.com
                        <i className="fa-regular fa-envelope"></i>
                    </div> */}

              <div className="col-lg-3 col-12 text-end">
                0111 35 333 06
                <i className="fa-brands fa-whatsapp"></i>
              </div>
            </div>
            <div className="footer-social-media py-1">
              <a
                href="https://www.facebook.com/FansofChemistry2020?mibextid=AQCFcj8imgClWYJ5"
                target="_blank"
              >
                <i className="fa-brands fa-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/mahmoud.elgharabawy.52/?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"
                target="_blank"
              >
                <i className="fa-brands fa-instgram"></i>
              </a>
              <a
                href="https://www.tiktok.com/@mahmoud.elgharaba1?_t=8irZWjFn0FR&_r=1"
                target="_blank"
              >
                <i className="fa-brands fa-tiktok"></i>
              </a>
              <a
                href="https://x.com/MahmoudElGhar1?t=IUxCqzqqhq11wEXKnVJzaA&s=09&mx=2"
                target="_blank"
              >
                <i className="fa-brands fa-x"></i>
              </a>
              <a
                href="https://www.youtube.com/@mahmoudelgharabawy4195"
                target="_blank"
              >
                <i className="fa-brands fa-youtube"></i>
              </a>
            </div>
          </div>
          <ul className="terms-conditions">
                <li>
                  <Link to={'/privacy-policy'}> سياسة الخصوصية </Link>
                </li>
                <li>
                  <Link to={'/refund-policy'}> سياسة الاسترجاع </Link>
                </li>
                <li>
                  <Link to={'/terms-and-conditions'}> البنود و الشروط </Link>
                </li>
          </ul>
          <div className="copyright">
            <div className="copyright-logo">
              <img className="w-100 h-100" src={companyLogo} alt="" />
            </div>
            <p>حقوق النشر © 2024 لشركة Easy Tech . كل الحقوق محفوظة.</p>
          </div>
        </div>
      </footer>
    </>
  );
}
