import React, { useContext, useState } from 'react'
import Swal from 'sweetalert2'
import { auth } from '../../../Context/AuthStore'
import config from '../../../config'
import axios from 'axios'
import avatar from '../../../assets/admin.png'
import { useNavigate } from 'react-router-dom'

export default function UserRow({ data , refresh}) {
    const navigate = useNavigate()
    const [isDeleting, setisDeleting] = useState(false)
    const { userAuth } = useContext(auth)

    const deleteUser = () => {
        setisDeleting(true)
        Swal.fire({
            title: `هل انت متاكد من حذف المستخدم : ${data.name}`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: 'لا شكرا',
            showConfirmButton: true,
            confirmButtonText: 'حذف',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                axios.delete(`${config.basURL}dashboard/user-destroy/${data.user.username}`, {
                    headers: {
                        'Authorization': config.ApiKey,
                        'auth': `Bearer ${userAuth}`,
                    }
                }).then((res) => {
                    setisDeleting(false)
                    refresh();
                    Swal.fire({
                        text: "تم الحذف بنجاح",
                        icon: "success",
                    });
                }).catch((err) => { console.log(err); setisDeleting(false) })

            }
            setisDeleting(false)
        })
    }

    return <>
        <div className="user-row">
            <div className="right">
                <div className="image">
                    <img src={avatar} alt="" className='w-100 h-100' />
                </div>
                <div className="user-info">
                    <p>  الاسم : {data.name} </p>
                    <p> البريد الالكتروني :  {data.email ? data.email : 'لا يوجد'} </p>
                </div>
            </div>
            <div className="left">
                <button className='edit' onClick={() => navigate(`/dashboard/update-staff/${data.id}`)}><i className="fa-solid fa-pen"></i></button>
                <button className='delete' onClick={deleteUser} disabled={isDeleting}>
                    {
                        isDeleting ? <i className="fa-solid fa-circle-notch fa-spin"></i> :  <i class="fa-regular fa-trash-can"></i>
                    }
                </button>
            </div>
        </div>
    </>
}
