import React from 'react'

export default function PlateformInstractions() {
    return <>
        <div className='courses-title instraction-page'>
            <div className='content'>
                <h5>
                    تعليمات المنصه
                </h5>
            </div>
        </div>
        <div className='instractions'>
            <div className='container'>
                <h4 className='text-center fw-bold mb-5'>
                    قبل ما تشترك في المنصه لازم تعرف التعليمات دي وتلتزم بيها :
                </h4>
                <p>
                    1-المنصه دي عباره عن ويب سايت بيتفتح من جوجل كروم فقط مبتتفتحش من اي متصفح تاني
                </p>
                <p>
                    2-متاح تفتح المنصه من الموبايل او اللابتوب
                </p>
                <p>
                    3-لو معاك ايفون لازم تنزل جوجل كروم(المنصه مبتفتحش من متصفح سفاري)
                </p>
                <p>
                    4-وانت بتعمل حساب لازم تكون بياناتك صحيحة(اسمك رباعي -رقم الواتساب بتاعك- رقم ولي أمرك )
                </p>
                <p>
                    5-لازم تحفظ الباسورد بتاعك
                </p>
                <p>
                    6-كل فيديو في المنصه متاح تتفرج عليه 4 مرات فقط عشان كدا لازم تتأكد من جوده النت وتكون الشبكه كويسه جدا قبل ما تفتح الفيديوهات
                </p>
                <p>
                    7-يجب الالتزام بمشاهدة الفيديوهات وحل الواجب والامتحانات
                </p>
                <p>
                    8-يتم ارسال تقرير دوري بالمستوى لولي الأمر لمتابعه مستواك
                </p>
                <p>
                    9-اي طالب غير ملتزم مش هيكمل معانا
                </p>

                <h5 className='text-center'>
                احنا معاك عشان تفرح بالنتيجة وتحقق حلمك وتفرح اهلك
                </h5>
            </div>
        </div>
    </>
}