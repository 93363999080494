import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { RouterProvider } from "react-router-dom";
import router from "./Router/Router.js";
import "./AppDark.css";
import AuthContextProvider from "./Context/AuthStore.js";
import DarkModeProvider from "./Context/DarkModeContext.js";
import { useEffect, useState } from "react";

function App() {
  let [spinner, setspinner] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setspinner(false);
    },2400);
  }, []);
  return (
    <>
      {spinner ? (
        <div className="loading-sp">
         <div class="loader"></div>
        </div>
      ) : (
        <>
          <DarkModeProvider>
            <AuthContextProvider>
              <RouterProvider router={router} />
            </AuthContextProvider>
          </DarkModeProvider>
        </>
      )}
    </>
  );
}

export default App;
