import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { auth } from '../../../Context/AuthStore'
import config from '../../../config'
import Placeholder from '../../../Components/Used/Placeholder'
import Swal from 'sweetalert2'


export default function UpdateNews() {

    const [isLoading, setisLoading] = useState(false)

    return <>
        <div className="container-fluid dashboard-last-news">
            <h3>
                تعديل اخر الاخبار
            </h3>
            <form>
                <div className="group">
                    <label htmlFor="image"> اختر الصوره </label>
                    <input type="file" name='image' id='image' />
                </div>
                <div className="group">
                    <label htmlFor="discription"> الوصف  </label>
                    <textarea name="discription" id="discription"></textarea>
                </div>
                <button>
                    {
                        isLoading ? <i className="fa-solid fa-circle-notch fa-spin"></i> : 'اضافه'
                    }
                </button>
            </form>
        </div>
    </>
}
