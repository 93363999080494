import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import config from '../../../config';
import { auth } from '../../../Context/AuthStore';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import UpdateLesson from './UpdateLesson';

export default function DashboardLessonBox({ data, open, unitId, refresh , openUpdate }) {

    const { userAuth } = useContext(auth)

    const [openPopup, setOpenPopup] = useState(false)


    const toggleOpenPopup = () => {
        openPopup ? setOpenPopup(false) : setOpenPopup(true)
    }

    function getHumanDate(d) {
        const date = new Date(d);
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('ar', options);
    }

    const deleteLesson = (id) => {
        Swal.fire({
            title: `هل انت متاكد من حذف الدرس : ${data.name}`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: 'لا شكرا',
            showConfirmButton: true,
            confirmButtonText: 'حذف',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                axios.delete(`${config.basURL}dashboard/lesson-destroy-view/${id}`, {
                    headers: {
                        'Authorization': config.ApiKey,
                        'auth': `Bearer ${userAuth}`,
                    }
                }).then(res => {
                    refresh()
                    Swal.fire({
                        text: 'تم الحذف بنجاح',
                        icon: 'success'
                    })
                })
            }
        })
    }

    useEffect(() => {
        console.log(data)
    } , [])

    return <>
        <div className="lesson-course-box dashboard-row-box">
            {/* <div className="image">
                <img src="" alt="" className='w-100 h-100' />
            </div> */}
            <p className='title '>
                {data.name}
            </p>
            {/* <div className="videos separate">
                <i className="fa-solid fa-desktop"></i>
                <p className='m-0'>
                    4 فيديوهات
                </p>
            </div> */}
            <div className="quiz separate">
                <i className="fa-solid fa-paste"></i>
                <p className='m-0'>
                    {data.exam_set.length} امتحان
                </p>
            </div>
            <div className='homework separate'>
                <i className="fa-solid fa-book-open"></i>
                <p className='m-0'>
                    {data.lesson_quiz.length} واجب
                </p>
            </div>
            <div className='views separate'>
                <i className="fa-solid fa-file"></i>
                <p className='m-0'>
                    الفايلات {data.lesson_file.length}
                </p>
            </div>
            <div className='pending separate'>
                {
                    data.pending ? ' غير مفعل ' : ' مفعل '
                }
            </div>
            <div className="date-setting">
                <div className='date'>
                    <p className='m-0'>
                        التاريخ
                    </p>
                    <p className='m-0'>
                        {getHumanDate(data.created)}
                    </p>
                </div>
                <div className="controll-btns">
                    <Link to={`/dashboard/exams/${data.id}/lesson`}> الامتحانات </Link>
                    <Link to={`/dashboard/quizes/${data.id}`}> الواجب </Link>
                    <Link to={`/dashboard/course-files/${data.id}/lesson`}> الفايلات </Link>
                    <button className='setting' onClick={toggleOpenPopup}>
                        <i className="fa-solid fa-gear"></i>
                    </button>
                    <button className='delete' onClick={() => deleteLesson(data.id)}>
                        <i className="fa-regular fa-trash-can"></i>
                    </button>
                </div>
            </div>
        </div>

        <UpdateLesson data={data} refresh={refresh} openUpdate={toggleOpenPopup} open={openPopup}/>
    </>
}
