import React, { useContext, useEffect, useState } from 'react'
import Validator from '../../../Validator'
import { auth } from '../../../Context/AuthStore'
import config from '../../../config'
import axios from 'axios'
import Swal from 'sweetalert2'

export default function UpdatePermissions({ staffPermissions, username , refresh }) {

    const { userAuth } = useContext(auth)
    const [isloading, setisloading] = useState(false)
    const [permissions, setpermissions] = useState(false)
    // const [choosedPerm, setchoosedPerm] = useState([...staffPermissions])

    const chekPerm = (perm) => {
        let check = staffPermissions.filter(p => {
            return p.codename == perm.codename
        })
        return check.length > 0 ? true : false
    }

    const addNewPerm = (e) => {
        let data = permissions.filter(perm => {
            return perm.codename == e.target.getAttribute('data-d')
        })[0]

        let new_data = [
            {
                codename: data.codename,
                content_type: data.content_type
            }
        ]
        add(new_data);
    }

    const getAllPerm = () => {
        axios.get(`${config.basURL}dashboard/list-permission-view`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            // console.log(res.data)
            setpermissions(res.data.results)
        }).catch(err => {
            console.log(err)
        })
    }

    const add = (data) => {
        setisloading(true)
        axios.post(`${config.basURL}dashboard/set-permission-view/${username}`, data, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            setisloading(false)
            refresh()
            Swal.fire({
                text: ' تم التعديل بنجاح ',
                icon: 'success'
            })
        }).catch(err => {
            setisloading(false)
            if (err.response.status == 401) {
                Swal.fire({
                    text: ' لا يوجد صلاحيات لهذا الحساب للقيام بهذا التعديل ',
                    icon: 'error'
                })
            } else {
                Swal.fire({
                    text: ' حدث خطا برجاء المحاوله مره اخري ',
                    icon: 'error'
                })
            }
            console.log(err)
        })
    }

    useEffect(() => {
        getAllPerm()
    }, [userAuth])

    return <>
        {
            <div className="add-user add-user-permission">
                <div className="left">
                    <div className="permissions">
                        <h6>
                            الصلاحيات
                        </h6>
                        <ul>
                            {
                                permissions && permissions.map(perm => <li className={`${chekPerm(perm) ? 'active' : ''}`} key={perm.id} onClick={addNewPerm} data-d={perm.codename}>
                                    <i className="fa-solid fa-check"></i>
                                    {perm.name}
                                </li>)
                            }
                        </ul>
                    </div>
                    <div className='add-btn'>
                        {/* <button disabled={isloading}>
                            {
                                isloading ? <i className="fa-solid fa-circle-notch fa-spin"></i> : 'تعديل صلاحيات'
                            }

                        </button> */}
                    </div>
                </div>
                <div className={`loading ${isloading ? 'open' : ''}`}>
                    <span className="loader"></span>
                </div>
            </div>
        }

    </>
}
