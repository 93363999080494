import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { auth } from '../../../Context/AuthStore'
import Placeholder from '../../../Components/Used/Placeholder'
import config from '../../../config'

export default function UserInvoices() {

    const [allInvoices, setallInvoices] = useState(false)
    const { userAuth } = useContext(auth)

    const getInvoices = () => {
        axios.get(`${config.basURL}subscription/last-invoices`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            // console.log(res.data)
            setallInvoices(res.data.results)
        }).catch(err => {
            console.log(err)
        })
    }

    function getHumanDate(d) {
        const date = new Date(d);
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric',hour: 'numeric' };
        return date.toLocaleDateString('ar', options);
    }

    useEffect(() => {
        getInvoices()
    }, [userAuth])

    return <>
        <div className='user-invoices w-100 scrolled'>
            <h4 className='py-3'>
                الفواتير
            </h4>
            <div className='w-100'>
                <table class="table table-bordered">
                    <thead class="table-dark">
                        <tr>
                            <th className='text-center' scope="col">#</th>
                            <th className='text-center' scope="col">اسم الكورس</th>
                            <th className='text-center' scope="col">تاريخ انتهاء صلاحيه  الفاتوره</th>
                            <th className='text-center' scope="col">السعر</th>
                            <th className='text-center' scope="col">حاله الفتوره</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allInvoices ? (allInvoices.length > 0 ? allInvoices.map((invoic, index) => <tr>
                                <th className='text-center' scope="row"> {index + 1} </th>
                                <td className='text-center'>{invoic.course_name}</td>
                                <td className='text-center'> {getHumanDate(invoic.payment_expiry)} </td>
                                <td className='text-center'> {invoic.amount} </td>
                                <td className='text-center'> {invoic.order_status} </td>
                            </tr>) : <tr> <td colSpan={6} className='text-center py-2'> لا يوجد فواتير </td> </tr>) : <Placeholder />
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </>
}
