import React, { useContext, useEffect, useState } from 'react'

import Validator from '../../../Validator'
import { auth } from '../../../Context/AuthStore'
import config from '../../../config'
import axios from 'axios'
import Swal from 'sweetalert2'
import AddPermissions from './AddPermissions'
import { useParams } from 'react-router-dom'
import UpdatePermissions from './UpdatePermissions'

export default function UpdateStaff() {

    const {staff_id} = useParams()
    const { userAuth } = useContext(auth)
    const [isLoading, setisLoading] = useState(false)
    const [currentUser, setcurrentUser] = useState(false)
    const [errors, seterrors] = useState({})
    const [updatedData, setupdatedData] = useState({})
    const [staffPermissions, setstaffPermissions] = useState([])

    const updateData = (e) => {
        let data = {...updatedData}
        data[e.target.name] = e.target.value;
        setupdatedData(data)
    }

    const getStaffData = () => {
        axios.get(`${config.basURL}dashboard/details-staff/${staff_id}` , {
            headers: {
                'Authorization': config.ApiKey ,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            // console.log(res.data)
            setcurrentUser(res.data.staff)
            setstaffPermissions(res.data.permissions)
        }).catch(err => {
            console.log(err)
        })
    }

    const send = (e) => {
        e.preventDefault()
        setisLoading(true)
        let vaildation = new Validator

        if(Object.keys(updatedData).length == 0){
            setisLoading(false)
            Swal.fire({
                text: ' لا يوجد اي تعديل ' ,
                icon: 'warning'
            })
            return;
        }

        Object.keys(updatedData).map(key => {
            vaildation.setKey(key).setValue(updatedData[key])
        })

        if(vaildation.haveError){
            setisLoading(false)
            seterrors(vaildation.getErrors())
            return;
        }

        axios.put(`${config.basURL}dashboard/student-update/${staff_id}`, updatedData , {
            headers: {
                'Authorization': config.ApiKey ,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            setisLoading(false)
            getStaffData()
            Swal.fire({
                text: ' تم اتعديل بنجاح ' ,
                icon: 'success'
            })
            // console.log(res.data)
        }).catch(err => {
            setisLoading(false)
            if(err.response.status == 401){
                Swal.fire({
                    text: ' لا يوجد صلاحيات لهذا الحساب للقيام بهذا التعديل ' ,
                    icon: 'error'
                })
            }
            console.log(err)
        })

    }

    useEffect(()=>{
        getStaffData()
    } , [userAuth])

    return <>
        <div className="container-fluid dashboard-add-user-page">
            <h1>
                تعديل مستخدم
            </h1>
            <form className="add-user mb-3" onSubmit={send} id='add-user-form'>
                <div className="left">
                    <h6>
                        بيانات المستخدم
                    </h6>
                    <div className="inputs">
                        <div>
                            <label htmlFor="name">الاسم : {currentUser && currentUser.name} </label>
                            <input type="text" id='name' name='name' placeholder='الاسم ' onChange={updateData} />
                            <p className='m-0 text-danger'> {errors['name']} </p>
                        </div>
                        <div>
                            <label htmlFor="email"> البريد الالكتروني : {currentUser && currentUser.email}</label>
                            <input type="email" id='email' name='email' onChange={updateData} />
                            <p className='m-0 text-danger'> {errors['email']} </p>
                        </div>
                        <div>
                            <label htmlFor="username">  أسم المستخدم : {currentUser && currentUser.user.username}</label>
                            <input type="text" id='username' name='username'  onChange={updateData} />
                            <p className='m-0 text-danger'> {errors['username']} </p>
                        </div>
                    </div>
                    <div className='add-btn'>
                        <button disabled={isLoading}>
                            {
                                isLoading ? <i className="fa-solid fa-circle-notch fa-spin"></i> : ' تعديل المستخدم '
                            }
                        </button>
                    </div>
                </div>
            </form>
            <UpdatePermissions staffPermissions={staffPermissions} username={currentUser && currentUser.user.username} refresh={getStaffData} />
        </div>
    </>
}
