import React, { useEffect, useRef } from 'react'
import Swal from 'sweetalert2';

export default function Videocipher({ avilabile, videoData, firstTime, watchTime, sendWatchTime, intervalID, setIntervalID, setWatchTime, sendWatchTry }) {

    const player = useRef();
    const iframe = useRef();

    useEffect(() => {
        player.current = window.VdoPlayer.getInstance(iframe.current);
    }, [])

    // record user watch time
    useEffect(() => {
        let hasLogged = false;
        let cumulativeTime = 0;
        let lastTime = 0;

        let handller = () => {
            // Calculate time watched since the last update
            const deltaTime = player.current.video.currentTime - lastTime;
            lastTime = player.current.video.currentTime;

            // Only add positive deltas to the cumulative time (skip backwards seeks)
            if (deltaTime > 0) {
                cumulativeTime += deltaTime;
            }

            const duration = player.current.video.duration;
            const targetTime = duration * 0.3;

            if (!hasLogged && cumulativeTime >= targetTime) {
                sendWatchTry()
                console.log("User has cumulatively watched 30% of the video.");
                hasLogged = true; // Ensure the log happens only once
            }
        }

        const handleSeek = function () {
            lastTime = player.current.video.currentTime;
        }

        // Update lastTime on seek (to handle when user manually changes the play position)
        
        player.current.video.addEventListener('timeupdate', handller)
        player.current.video.addEventListener('seeking',handleSeek );
        return () => {
            player.current.video.removeEventListener('timeupdate', handller)
            player.current.video.removeEventListener('seeking', handleSeek)
        }

    }, [firstTime, watchTime])

    // pause record user watch time
    useEffect(() => {
        const pauseHandler = () => {
            clearInterval(intervalID)
            sendWatchTime()
        }

        player.current.video.addEventListener('pause', pauseHandler);

        return () => {
            // removing event listener
            player.current.video.removeEventListener('pause', pauseHandler)
        }

    }, [intervalID, watchTime])

    // handle when video is end
    useEffect(() => {
        const handleEndedVideo = () => {
            sendWatchTime();
            clearTimeout(intervalID);
        }
        player.current.video.addEventListener('ended', handleEndedVideo)
        return () => {
            player.current.video.removeEventListener('ended', handleEndedVideo)
        }
    }, [watchTime])

    // stop counter if video not avilabile
    useEffect(() => {
        if (!avilabile) {
            clearInterval(intervalID)
            Swal.fire({
                text: 'انتهت مشاهداتك لهذا الدرس',
                icon: 'info'
            })
        }
    }, [avilabile])

    return <>
        {
            avilabile ? <iframe
                ref={iframe}
                src={`https://player.vdocipher.com/v2/?otp=${videoData && videoData.otp}&playbackInfo=${videoData && videoData.playbackInfo}`}
                style={{ 'border': 0 }}
                allow="encrypted-media"
                allowFullScreen
                id='frame'
            ></iframe> :
                <div className="alert alert-info text-center">انتهت مشاهداتك للدرس</div>
        }
    </>
}
