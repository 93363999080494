import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import config from '../config';
import { auth } from '../Context/AuthStore';
import CourseContentCollapse from '../Components/Courses/CourseContentCollapse';
import Placeholder from '../Components/Used/Placeholder';
import Swal from 'sweetalert2';
export default function CreateInvoice() {

    const { id } = useParams()
    const { userAuth } = useContext(auth)
    const navigate = useNavigate()

    const [courseData, setcourseData] = useState(false)
    const [lastInvoice, setlastInvoice] = useState(false)

    const getCourseData = () => {

        axios.get(`${config.basURL}course/course-details/${id}`, {
            headers: {
                'Authorization': config.ApiKey,
            }
        }).then((res) => {
            // console.log(res.data)
            setcourseData(res.data);
        }).catch((err) => {
            console.log(err)
        })
    }

    const invoice = () => {
        axios.get(`${config.basURL}subscription/last-invoice-course/${id}`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            // console.log(res.data)
            setlastInvoice(res.data.results)
        }).catch(err => {
            console.log(err)
        })
    }

    const goToInvoice = () => {
        
        Swal.fire({
            title: "شروط الاشتراك في الكورس",
            text: ` انسخ اسم الكورس " ${courseData.name} " و بعدين دوس موافق علي الشروط ` ,
            input: "text",
            inputPlaceholder: 'انسخ اسم الكورس هنا',
            confirmButtonText: 'موافق علي الشروط',
            showCancelButton: true,
            cancelButtonText: 'الغاء',
            inputValidator: (value) => {
                if (!value) {
                    return "اكتب اسم الكورس الاول";
                }else if (value != courseData.name) {
                    return 'اكتب اسم الكورس صح الاول'
                }
            }
        }).then(res => {
            if(res.isConfirmed){
                navigate(`/invoice-code/${id}`)
            }
        })
    }

    function getHumanDate(d) {
        const date = new Date(d);
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', };
        return date.toLocaleDateString('ar', options);
    }

    useEffect(() => {
        getCourseData()
        invoice()
    }, [userAuth])

    return <>
        <div className='course-invoice-page p-3'>
            {
                courseData && <>

                    <h2 className='fw-bold'>
                        {courseData.name}
                    </h2>
                    <p>
                        {courseData.about}
                    </p>
                    <div className='d-flex align-items-center py-2'>
                        <p className='year'> {courseData.year.name} </p>
                    </div>
                </>
            }
        </div>
        <div className="last-ivoices p-3 container">
            <h3>
                الفواتير السابقه
            </h3>
            <table class="table table-bordered">
                <thead className='table-dark'>
                    <tr>
                        <th className='text-center' scope="col">#</th>
                        <th className='text-center' scope="col">اسم الكورس</th>
                        {/* <th className='text-center' scope="col"> رقم الفتوره </th> */}
                        <th className='text-center' scope="col">تاريخ انتهاء صلاحيه  الفاتوره</th>
                        <th className='text-center' scope="col">السعر</th>
                        <th className='text-center' scope="col">العدد</th>
                        <th className='text-center' scope="col">حاله الفاتوره</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        lastInvoice ? (lastInvoice.length > 0 ? lastInvoice.map((invoic, index) => <tr>
                            <th className='text-center' scope="row"> {index + 1} </th>
                            <td className='text-center'>{invoic.course_name}</td>
                            {/* <td className='text-center'>{ invoic.reference_number }</td> */}
                            <td className='text-center'> {getHumanDate(invoic.payment_expiry)} </td>
                            <td className='text-center'> {invoic.amount} </td>
                            <td className='text-center'> 1 </td>
                            <td className='text-center'> {invoic.order_status} </td>
                        </tr>) : <tr> <td colSpan={6} className='text-center py-2'> لا يوجد فواتير </td> </tr>) : <Placeholder />
                    }
                </tbody>
            </table>
            <button className='create-new-invoice' onClick={goToInvoice}>
                <i className="ms-2 fa-solid fa-file-invoice"></i>
                الذهاب للدفع
            </button>
        </div>
        <div className="container">
            <h5 className='py-3'>
                محتوى الكورس
            </h5>
            <div className='row w-100 p-2'>
                {
                    courseData ? (courseData.course_units.length > 0 ? courseData.course_units.map((unit) => <CourseContentCollapse key={unit.id} data={unit} />) : <p className='text-center py-3'>  </p>) : <Placeholder />
                }
            </div>
        </div>
    </>
}
