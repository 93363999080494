import React, { useState } from 'react'

export default function StepOne({ updateUserData, clearInputData, registerData, show, errors }) {

    return <>
        <form className={`container first-step-form form-steps ${show ? '' : 'd-none'}`}>
            <div className='row gap-2'>
                <div className="col-lg-11">
                    <div className={`input-style ${errors['name'] ? 'error' : ''}`}>
                        <input onChange={updateUserData} name='name' type='text' placeholder='الاسم الرباعي' value={registerData['name']} required />
                        <div className={`clear-input ${registerData['name'] === '' ? 'd-none' : ''}`} onClick={() => clearInputData('name')}>
                            <i className="fa-solid fa-circle-xmark"></i>
                        </div>
                    </div>
                    <ul className={errors['name'] ? '' : 'd-none'}>
                        {
                            errors['name'] && Object.values(errors['name']).map(error => <li className='text-danger'> {error}  </li>)
                        }
                    </ul>
                </div>

                {/* <div className="col-lg-11">
                    <div className={`input-style ${errors['lastName'] ? 'error' : ''}`}>
                        <input onChange={updateUserData} name='lastName' type='text' placeholder=' الاسم الاخير' value={registerData['lastName']} required />
                        <div className={`clear-input ${registerData['lastName'] === '' ? 'd-none' : ''}`} onClick={() => clearInputData('lastName')}>
                            <i className="fa-solid fa-circle-xmark"></i>
                        </div>
                    </div>
                    
                </div> */}
            </div>
        </form>
    </>
}
