import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import config from '../../../config'
import { auth } from '../../../Context/AuthStore'
import Placeholder from '../../../Components/Used/Placeholder'
import { Link, useNavigate, useParams } from 'react-router-dom'
import CSVMake from '../../../CSVMake'
import Swal from 'sweetalert2'

export default function StudentAnswers() {

    const [examResultsData, setExamResultsData] = useState(false)
    const { userAuth } = useContext(auth)
    const { exam_id } = useParams()

    const navigate = useNavigate()

    function getHumanDate(d) {
        const date = new Date(d);
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        return date.toLocaleDateString('ar', options);
    }

    const deleteAnswer = (id) => {
        Swal.fire({
            title: `هل انت متاكد من حذف نتيجه الامتحان`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: 'لا شكرا',
            showConfirmButton: true,
            confirmButtonText: 'حذف',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                axios.delete(`${config.basURL}dashboard/delete-result`, {
                    headers: {
                        'Authorization': config.ApiKey,
                        'auth': `Bearer ${userAuth}`,
                    },
                    data: { result_id: id }
                }).then(res => {
                    getExamResults()
                    Swal.fire({
                        text: 'تم الحذف بنجاح يمكن للطالب الدخول الي الامتحان مره اخري',
                        icon: 'success'
                    })
                })
            }
        })
    }

    const getExamResults = () => {
        axios.get(`${config.basURL}dashboard/exam-results/${exam_id}`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            let exanAnswers = res.data.filter(answer => answer.exam != null)
            setExamResultsData(exanAnswers)
            console.log(exanAnswers)
        }).catch(err => console.log(err))
    }

    const exportCsv = () => {
        let csv = new CSVMake;
        csv.setTableHeader({
            student: ['name', 'أسم الطالب'],
            exam: ['name', 'اسم الامتحان'],
            // question_answer: 'عدد الاسئله',  
            degree: 'درجه الطالب',
            created: 'تاريخ الدخول',
            time_taken: 'الوقت المستغرق في الامتحان'
        }).setData(examResultsData, {
            student: 'name',
            exam: 'name'
        }).exportFile(` نتائج الامتحانات `)
    }

    useEffect(() => {
        getExamResults()
    }, [userAuth])

    return <>
        <div className="container-fluid dashboard-students-section ">
            <div className="head">
                <h6 className='m-0 p-0'>
                    نتائج الطلاب
                </h6>
                {
                    examResultsData && <button className='export-file' onClick={exportCsv}>
                        <i className="fa-regular fa-file-excel"></i>
                        انشاء ملف Excel
                    </button>
                }
            </div>
            <table className='students-info student-table exam-results'>
                <thead>
                    <tr>
                        <th className='text-center'>
                            أسم الطالب
                        </th>
                        <th className='text-center'>
                            أسم الامتحان
                        </th>
                        <th className='text-center'>
                            عدد الاسئله
                        </th>
                        <th className='text-center'>
                            درجه الامتحان
                        </th>
                        <th className='text-center'>
                            وقت الدخول
                        </th>
                        <th className='text-center'>
                            الوقت المستغرق في الامتحان
                        </th>
                        <th className='text-center'>
                            اجابات الطالب
                        </th>
                        <th className='text-center text-danger'>
                            مسح النتيجه
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        examResultsData ? (examResultsData.length > 0 ? examResultsData.map((exam, index) => <tr key={index} >
                            <td className='text-center'>
                                {
                                    exam.student.name
                                }
                            </td>
                            <td className='text-center'>
                                {
                                    exam.exam.name
                                }
                            </td>
                            <td className='text-center'>
                                {
                                    exam.question_answer.length
                                } سؤال
                            </td>
                            <td className='text-center'>
                                {exam.degree}
                            </td>
                            <td className='text-center'>
                                {getHumanDate(exam.created)}
                            </td>
                            <td className='text-center'>
                                {exam.time_taken} دقائق
                            </td>
                            <td className='text-center'>
                                <button onClick={() => navigate(`/dashboard/show-student-answers/${exam.id}`)}>
                                    عرض الاجابات
                                </button>
                            </td>
                            <td className='text-center text-danger'>
                                <button onClick={() => deleteAnswer(exam.id)}>
                                    مسح النتيجه
                                </button>
                            </td>
                        </tr>) : <tr><td className='w-100' colSpan={7}> لا يوجد نتائج </td></tr>) : <tr><td className='w-100' colSpan={6}> <Placeholder /> </td></tr>
                    }
                </tbody>
            </table>
        </div>
    </>
}
