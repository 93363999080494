import React, { useState } from 'react'
import Swal from 'sweetalert2';

export default function SearchBox({ feilds, search, open, switchOpen, loading , currentPage }) {

    const [params, setparams] = useState({})

    const addParam = (e) => {
        let data = { ...params }
        data[e.target.name] = e.target.value;
        if (e.target.value == '') {
            delete data[e.target.name]
        }
        setparams(data)
    }

    const close = (e) => {
        e.target.classList.contains('dashboard-search-box-layout') && switchOpen();
    }

    const searchHandller = (e) => {
        e.preventDefault()
        currentPage(1)
        let query = ''
        // create query
        if (Object.keys(params).length == 0) {
            Swal.fire({
                text: ' انت مكتبتش اي حاجه في السيرش ',
                icon: 'warning'
            })
            return;
        }

        Object.keys(params).map((param, index) => {
            if (index == 0) {
                query += `?${param}=${params[param]}`
            } else {
                query += `&${param}=${params[param]}`
            }
        })

        search(query , 1)
        setparams({})
        let inputs = document.querySelectorAll('.dashboard-search-box-layout .search input')
        for(let i =0 ; i < inputs.length;i++){
            inputs[i].value = ""
        }
    }

    return <>
        <div className={`dashboard-search-box-layout ${open ? 'open' : ''}`} onClick={close}>
            <form className="search" onSubmit={searchHandller} >
                {
                    feilds.map(f => {
                        return <>
                            {
                                f.input && <div className="group">
                                    <label htmlFor={f.name}> {f.label} </label>
                                    <input type="text" name={f.name} id={f.name} onChange={addParam} />
                                </div>
                            }
                            {
                                f.select && <div className="group">
                                    <label htmlFor={f.name}> {f.label} </label>
                                    <select name={f.name} id={f.name} onChange={addParam} >
                                        {
                                            f.options && f.options.map(option => {
                                                return <>
                                                    <option value={option.id}> {option.name} </option>
                                                </>
                                            })
                                        }
                                    </select>
                                </div>
                            }
                        </>
                    })
                }
                <button>
                    {
                        loading ? <i className="fa-solid fa-circle-notch fa-spin"></i> : <>
                            <i className="ms-2 fa-solid fa-magnifying-glass"></i>
                            بحث
                        </>
                    }

                </button>
            </form>
        </div>
    </>
}
