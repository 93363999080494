import React, { useContext, useEffect, useState } from 'react'
import Validator from '../../../Validator'
import { auth } from '../../../Context/AuthStore'
import config from '../../../config'
import axios from 'axios'
import Swal from 'sweetalert2'
import Placeholder from "../../../Components/Used/Placeholder"
export default function AddPermissions(props) {

    const { userAuth } = useContext(auth)
    const [isloading, setisloading] = useState(false)
    const [permissions, setpermissions] = useState(false)
    const [choosedPerm, setchoosedPerm] = useState([])
    const [displayPerms, setdisplayPerms] = useState([])
    const [bySearch, setbySearch] = useState(false)

    const addNewPerm = (e) => {

        let data = permissions.filter(p => p.id == e.target.getAttribute('data-d'))[0],
            new_data = [...choosedPerm]

        if (e.target.classList.contains('active')) {
            e.target.classList.remove('active')
            let filter = new_data.filter(d => d.codename != data.codename)
            new_data = [...filter]
        } else {
            e.target.classList.add('active')
            new_data.push({
                codename: data.codename,
                content_type: data.content_type
            })
        }
        // console.log(new_data )
        setchoosedPerm(new_data)
    }

    const getAllPerm = () => {
        axios.get(`${config.basURL}dashboard/list-permission-view`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            // console.log(res.data)
            setpermissions(res.data.results)
        }).catch(err => {
            console.log(err)
        })
    }

    const send = (e) => {
        e.preventDefault()
        setisloading(true)
        if(choosedPerm.length == 0){
            Swal.fire({
                text: ' لم تقم باختيار اي صلاحيه ' ,
                icon: 'warning'
            })
            setisloading(false)
            return;
        }

        axios.post(`${config.basURL}dashboard/set-permission-view/${props.user.username}` , choosedPerm  , {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            setisloading(false)
            setchoosedPerm([])
            props.reset(false)
            Swal.fire({
                text: ' تم اضافه الصلاحيات بنجاح ' ,
                icon : 'success'
            })
            // console.log(res.data)
        }).catch(err => {
            setisloading(false)
            console.log(err)
        })
    }

    const searchPerm = (e) => {
        let perms = [...permissions]

        if(e.target.value == '') {
            setbySearch(false)
        }else {
            setbySearch(true)
        }

        let searchResult = perms.filter(perm => {
            return perm.name.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setdisplayPerms(searchResult)
        // console.log(searchResult)
    }

    useEffect(() => {
        getAllPerm()
    }, [userAuth])

    return <>
        {
            props.user &&
            <form className="add-user add-user-permission" onSubmit={send}>
                <div className="left">
                    <h3>
                        انت تقوم باضافه صلاحيات : {props.user.name}
                    </h3>
                    <div className='search-perm'>
                        <input type="text" name='search-perm' placeholder='ابحث بالاسم' onChange={searchPerm} />
                    </div>
                    <div className="permissions">
                        <h6>
                            الصلاحيات
                        </h6>
                        <ul>
                            {
                                permissions ?  (bySearch ? displayPerms.map(perm => <li key={perm.id} onClick={addNewPerm} data-d={perm.id}>
                                    <i className="fa-solid fa-check"></i>
                                    {perm.name}
                                </li> ): permissions.map(perm => <li key={perm.id} onClick={addNewPerm} data-d={perm.id}>
                                    <i className="fa-solid fa-check"></i>
                                    {perm.name}
                                </li>)) : <Placeholder /> 
                            }
                        </ul>
                    </div>
                    {/* <div className="account-type">
                        <h6>
                            نوع الحساب
                        </h6>
                        <ul>
                            <li>Admin</li>
                            <li>Super Admin</li>
                        </ul>
                    </div> */}
                    <div className='add-btn'>
                        <button disabled={isloading}>
                            {
                                isloading ? <i className="fa-solid fa-circle-notch fa-spin"></i> :'اضافة صلاحيات'
                            }
                            
                        </button>
                    </div>
                </div>
            </form>
        }

    </>
}
