import React, { useContext, useEffect, useState } from 'react'
import dashboardGradImage from "../../../assets/dashboard-grad.svg"
import avatar from "../../../assets/avatar.svg"
import StudentRow from '../../../Components/Dashboard/Home/StudentRow'
import bookImage from '../../../assets/book.svg'
import CircularProgress from '../../../Components/CircularProgress'
import axios from 'axios'
import config from '../../../config'
import { auth } from '../../../Context/AuthStore'
import Placeholder from "../../../Components/Used/Placeholder"
import { useNavigate } from 'react-router-dom'
export default function DashboardHome() {

    const { userAuth } = useContext(auth)
    const [totlaStudents, setTotlaStudents] = useState(0)
    const [lastStudents, setLastStudents] = useState(false)
    const [lastCourses, setLastCourses] = useState(false)

    const getOverview = () => {
        axios.get(`${config.basURL}dashboard/overview`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then((res) => {
            console.log(res.data)
            setTotlaStudents(res.data.total_student_count)
            setLastStudents(res.data.student_serializer)
            setLastCourses(res.data.courses_serializer)
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        getOverview()
    }, [userAuth])

    return <>
        <div className="container-fluid dashboard-home-section">
            <div className="welcome-box">
                <h3 className='title'>
                    أهلا بيك
                    <span className='admin-name'>

                    </span>
                </h3>
                <div className="new-subscriptions">
                    <h5 className='fw-bold'>
                        المشتركين
                        الجدد
                    </h5>
                    <div className='image'>
                        <img src={dashboardGradImage} alt="" className='w-100 h-100' />
                    </div>
                    <div className="avatars-container">
                        <p>{totlaStudents}</p>
                        <ul className="avatars">
                            <li>
                                <img src={avatar} alt="" />
                            </li>
                            <li>
                                <img src={avatar} alt="" />
                            </li>
                            <li>
                                <img src={avatar} alt="" />
                            </li>
                            <li>
                                <img src={avatar} alt="" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="analysis">
                <div className='right students'>
                    <h6 className='title'>
                        الطلاب
                    </h6>
                    <div className="content scrolled-y">
                        <div className='student-row'>
                            <div className="image">
                            </div>
                            <p className='student-name'>
                                اسم الطالب
                            </p>
                            <p className='serial-number'>
                                كود الطالب
                            </p>
                            <p className='phone-number'>
                                رقم الهاتف
                            </p>
                            <p className='phone-number'>
                                رقم ولي الامر
                            </p>
                        </div>
                        {
                            lastStudents && lastStudents.map((student, index) => <StudentRow key={index} data={student} />)
                        }

                    </div>
                </div>
                <div className="left">
                    <h6 className='title'>
                        اخر الكورسات
                    </h6>
                    <div className="last-courses">
                        {
                            lastCourses ? (lastCourses.length > 0 ? lastCourses.map(course => {
                                return <>
                                <div className='dashboard-home-last-courses'>
                                    <div className="image">
                                        <img src={course.cover} alt="" />
                                    </div>
                                    <div className="text">
                                        <h4> {course.name} </h4>
                                        <p>{course.about}</p>
                                    </div>
                                </div>
                                
                                </>
                            }) : <p className='text-center py-2'> لا سوجد كورسات حتي الان </p> ) : <Placeholder />
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

