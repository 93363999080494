import axios from 'axios'
import React, { useContext, useState } from 'react'
import { auth } from '../../../Context/AuthStore'
import config from '../../../config'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

export default function CourseFileBox({ data, course_id, refresh , type}) {

    const { userAuth } = useContext(auth)

    const navigate = useNavigate()

    const [isDeleting, setisDeleting] = useState(false)

    function getHumanDate(d) {
        const date = new Date(d);
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('ar', options);
    }

    const deleteFile = () => {
        let deleteData = {}
        type != 'course' ? deleteData.file_id = data.id : deleteData.id_file = data.id
        setisDeleting(true)
        Swal.fire({
            title: `هل انت متاكد من حذف الملف : ${decodeURIComponent( data.file.split('/')[(data.file.split('/').length - 1)])}`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: 'لا شكرا',
            showConfirmButton: true,
            confirmButtonText: 'حذف',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                axios.delete(`${config.basURL}dashboard/${type}-files-destroy/${data.id}`, {
                    headers: {
                        'Authorization': config.ApiKey,
                        'auth': `Bearer ${userAuth}`
                    }
                }).then(res => {
                    setisDeleting(false)
                    refresh();
                    Swal.fire({
                        text: 'تمت العمليه بنجاح',
                        icon: 'success'
                    })
                }).catch(err => {
                    setisDeleting(false)
                    console.log(err)
                    Swal.fire({
                        text: ' حدث خطاء يرجاء المحاوله مره اخري ' ,
                        icon: 'error'
                    })
                })
            }
            setisDeleting(false)
        })
    }

    return <>
        <div className="cousre-file-box col-lg-2">
            <div className="icon">
                <i className="fa-solid fa-file"></i>
            </div>
            <a className='file-name text-center d-block' href={`${config.basURL}${data.file}`} target='_blank' style={{ overflow: 'hidden' }}>
                {
                   decodeURIComponent( data.file.split('/')[(data.file.split('/').length - 1)])
                }
            </a>
            <p className='text-center'>
                {getHumanDate(data.created)}
            </p>
            <div className="box-footer">
                <button className='delete' onClick={deleteFile}>
                    {isDeleting ? <i className="fa-solid fa-circle-notch fa-spin"></i> : <i className="fa-regular fa-trash-can"></i>}
                </button>
            </div>
        </div>
    </>
}
