import React, { useEffect } from "react";
import "../about/about.css";
import AOS from "aos";
import aboutimg1 from "../../../../assets/gharabawy/ghabout.png";
import aboutimg2 from "../../../../assets/gharabawy/about1.gif";
import aboutimg3 from "../../../../assets/gharabawy/about2.gif";
import aboutimg4 from "../../../../assets/gharabawy/about3.gif";
import aboutimg5 from "../../../../assets/gharabawy/question.gif";
import aboutimg6 from "../../../../assets/gharabawy/about-shape.png";
import { FaSheetPlastic } from "react-icons/fa6";
export const About = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="about">
      <h1  data-aos="fade-down" data-aos-duration="1000">ليه منصتنا </h1>
      <div className="about-content">
        <div  data-aos="fade-left" data-aos-duration="1000" className="about-right">
          <img className="question" src={aboutimg5} alt="About" />
          <img className="imgg" src={aboutimg1} alt="About" />
        </div>
        <div  data-aos="fade-right" data-aos-duration="1000" className="about-left">
          <h3>د / محمـود الغرباوي</h3>
          <h1> 
          عشان احنا بنعرف نعلي علي اي حد وشعارنا 
          </h1>
          <h3>
          لن ارتاح ولن اترك احد يرتاح
          </h3>
          <img src="" alt="" srcSet={aboutimg6} />
        </div>
      </div>
    </div>
  );
};
