
let config = {
    basURL : 'https://api.mahmoud-elgharabawy.com/' ,
    ApiKey: 'Api-Key 7tQzPvpX.xOOFaquiEymEZTH72JHIcSJrmenkc55m'
};

// let config = {
//     basURL : 'http://13.38.44.202/' ,
//     ApiKey: 'Api-Key Rnqcw3EK.YV8Vn1uPmPrGEEOpa7FSurkLvedoun7G'
// };


export default config