import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { auth } from '../../../Context/AuthStore'
import config from '../../../config'
import Placeholder from '../../../Components/Used/Placeholder'
import Swal from 'sweetalert2'
import Validator from "../../../Validator"

export default function LastNews() {

    const { userAuth } = useContext(auth)
    
    const controller = useRef(null)
    const [uploadProgress, setuploadProgress] = useState(0)

    const [newNews, setNewNews] = useState([])
    const [lastNewsData, setlastNewsData] = useState(false)
    const [isLoading, setisLoading] = useState(false)

    const updateNewNewsData = (e) => {
        let data = [...newNews]

        switch (e.target.name) {
            case 'image':
                let reader = new FileReader()
                reader.onload = function (e) {
                    const base64String = e.target.result;
                    let img = `<div class="image"> <img src="${base64String}" /> </div>`
                    data[0] = img
                };
                reader.readAsDataURL(e.target.files[0])
                break;
            default:
                let paragraph = `<p>${e.target.value}</p>`
                data[1] = paragraph
                break;
        }
        setNewNews(data)
    }

    const createNewNews = (e) => {
        e.preventDefault()
        setisLoading(true)

        if (newNews.length === 0) {
            Swal.fire({
                text: 'برجاء اضافه صوره او نص',
                icon: 'warning'
            })
            setisLoading(false)
            return;
        }

        let data = {
            text: newNews.join(' ')
        }

        

        controller.current = new AbortController();

        axios.post(`${config.basURL}dashboard/create-news-view`, data, {
            signal: controller.current.signal,
            'headers': {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            },
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                const percent = Math.floor((loaded * 100) / total)
                setuploadProgress(percent)
            }
        }).then(res => {
            Swal.fire({
                text: 'تم الاضافه بنجاح',
                icon: 'success'
            })
            getLastNews()
            setisLoading(false)
        }).catch(err => {
            console.log(err)
            setisLoading(false)
            Swal.fire({
                text: 'حدث خطاء برجاء المحاوله مره اخري',
                icon: "error"
            })
        })
    }

    const deleteFile = (id) => {
        Swal.fire({
            title: `هل انت متاكد من حذف `,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: 'لا شكرا',
            showConfirmButton: true,
            confirmButtonText: 'حذف',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                axios.delete(`${config.basURL}dashboard/delete-news-view/${id}`, {
                    headers: {
                        'Authorization': config.ApiKey,
                        'auth': `Bearer ${userAuth}`
                    }
                }).then(res => {
                    getLastNews()
                    Swal.fire({
                        text: 'تمت العمليه بنجاح',
                        icon: 'success'
                    })
                }).catch(err => {
                    console.log(err)
                    Swal.fire({
                        text: ' حدث خطاء يرجاء المحاوله مره اخري ',
                        icon: 'error'
                    })
                })
            }
        })
    }

    const getLastNews = () => {
        axios.get(`${config.basURL}dashboard/list-news-view`, {
            'headers': {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            console.log(res.data)
            setlastNewsData(res.data.results)
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        getLastNews()
    }, [userAuth])

    return <>
        <div className="container-fluid dashboard-last-news">
            <h3>
                اخر الاخبار
            </h3>
            <form onSubmit={createNewNews}>
                <h4>
                    اضافه خبر جديد
                </h4>
                <div className="group">
                    <label htmlFor="image"> اختر الصوره </label>
                    <input type="file" name='image' id='image' onChange={updateNewNewsData} />
                </div>
                <div className="group">
                    <label htmlFor="discription"> الوصف  </label>
                    <textarea name="discription" id="discription" onChange={updateNewNewsData} ></textarea>
                </div>

                {
                    isLoading && <>
                        <div className='dashboard-upload-header'>
                            <p> جاري رفع الصوره.... </p>
                            <button onClick={() => controller.current.abort()}>
                                الغاء
                            </button>
                        </div>
                        <div className='upload-progress'>
                            <div className='text'>{uploadProgress}%</div>
                            <div className="bar">
                                <div style={{ width: `${uploadProgress}%` }}></div>
                            </div>
                        </div>
                    </>
                }

                <button>
                    {
                        isLoading ? <i className="fa-solid fa-circle-notch fa-spin"></i> : 'اضافه'
                    }
                </button>
            </form>

            <div className="news">
                {
                    lastNewsData ? (lastNewsData.length > 0 ? lastNewsData.map(news => <div key={news.id} >
                        <div className='content' dangerouslySetInnerHTML={{ '__html': news.text }} />
                        <div className="settings">
                            {/* <button className='edit'>تعديل </button> */}
                            <button className='delete' onClick={() => deleteFile(news.id)}>حذف</button>
                        </div>
                    </div>) : <p className='text-center py-2'> لا يوجد اخبار حتي الان</p>) : <Placeholder />
                }
            </div>
        </div>
    </>
}
