import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import UserRow from './UserRow'
import { auth } from '../../../Context/AuthStore'
import config from '../../../config'
import axios from 'axios'
import Swal from 'sweetalert2'
import Placeholder from '../../../Components/Used/Placeholder'

export default function Users() {
    const { userAuth } = useContext(auth)
    const [allStaff, setallStaff] = useState(false)
    const [usersCount, setusersCount] = useState(0)

    const getStaff = () => {
        axios.get(`${config.basURL}dashboard/list-staff`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            // console.log(res.data)
            setusersCount(res.data.count)
            setallStaff(false)
            setallStaff(res.data.results)
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        getStaff()
    }, [userAuth])

    return <>
        <div className="container-fluid dashboard-users-page">
            <h1>
                المستخدمين ( {usersCount} )
            </h1>
            {/* <div className="super-admins">
                <div className="section-header">
                    <h6>Super Admins</h6>
                    <span className="line"></span>
                </div>
                <div className="content">
                    <div className="user-row">
                        <div className="right">
                            <div className="image">
                                <img src="" alt="" className='w-100 h-100' />
                            </div>
                            <div className="user-info">
                                <p>اسم المستخدم</p>
                                <p>Username@gmail.com</p>
                            </div>
                        </div>
                        <div className="left">
                            <button className='edit'><i className="fa-solid fa-pen"></i></button>
                            <button className='delete'><i class="fa-regular fa-trash-can"></i></button>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="admins">
                <div className="section-header">
                    <h6>Admins</h6>
                    <span className="line"></span>
                </div>
                <div className="content">
                    {
                        allStaff ? (allStaff.length > 0 ? allStaff.map(user => <UserRow key={user.id} data={user} refresh={getStaff} />) : <p className='text-center w-100 py-2'> لا يوجد مستخدمين </p>) : <Placeholder />
                    }

                </div>
            </div>
            <div className="create-new">
                <span className="line"></span>
                <Link to={'/dashboard/add-user'}>
                    <div className="icon">
                        <i className="fa-solid fa-plus"></i>
                    </div>
                    <p>
                        اضافة مستخدم
                    </p>
                </Link>
            </div>
        </div>
    </>
}
