import React from 'react'

export default function Invoice({data}) {

    
    function getHumanDate(d) {
        const date = new Date(d);
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', };
        return date.toLocaleDateString('ar', options);
    }

    return <>
        <div className='invoice-row-box'>
            <div className="right">
                <div className="student-name">
                    <h6>
                        {data.student_name}
                    </h6>
                    <p>
                        {data.student_user_username}
                    </p>
                </div>
                <div className="invoice-date">
                    <h6>
                        تاريخ الفاتورة
                    </h6>
                    <p>
                        {getHumanDate(data.created)}
                    </p>
                </div>
                <div className="pay-date">
                    <h6>
                        تاريخ الدفع
                    </h6>
                    <p>
                        {
                            data.payment_time != '' ? getHumanDate(data.payment_time) : 'لم يتم الدفع ' 
                        }
                    </p>
                </div>
                <div className="course-name">
                    <h6>
                        الكورس
                    </h6>
                    <p>
                        {data.course_name}
                    </p>
                </div>
                <div className="course-name">
                    <h6>
                        رقم الفاتوره
                    </h6>
                    <p>
                        {data.reference_number}
                    </p>
                </div>
                <div className="price">
                    <h6>
                        المبلغ
                    </h6>
                    <p>
                        { data.amount } جنيه
                    </p>
                </div>
                <div className='payment-methoud'>
                    <h6>
                        طريقة الدفع
                    </h6>
                    <p>
                        {
                            data.order_status === 'MANUALLY' ? 'MANUALLY' : 'FawryPay'
                        }
                    </p>
                </div>
                <div className='payment-methoud'>
                    <h6>
                        حاله الفاتوره
                    </h6>
                    <p>
                        {
                            data.order_status
                        }
                    </p>
                </div>
                
            </div>
            <div className="left">
                {/* <div className='discount-code'>
                    <h6>
                        كود خصم 20%
                    </h6>
                    <p>
                        lsdol937
                    </p>
                </div> */}
                {/* <button>
                    <i className="fa-solid fa-chevron-up"></i>
                </button> */}
            </div>
        </div>
    </>
}
