import React from 'react'
import avatar from "../../../assets/avatar.svg"
import { useNavigate } from 'react-router-dom'

export default function StudentRow({data}) {

    const navigate = useNavigate()

    return <>
        <div className='student-row' onClick={() => navigate(`/dashboard/students/${data.id}`)} style={{cursor:'pointer'}}>
            <div className="image">
                <img src={avatar} alt="" className='w-100 h-100' />
            </div>
            <p className='student-name'>
                {data.name}
            </p>
            <p className='serial-number'>
                {data.code ? data.code : 'لا يوجد'}
            </p>
            <p className='phone-number'>
                {data.user.username}
            </p>
            <p className='phone-number'>
                {data.parent_phone}
            </p>
            {/* <p className='student-email'>
            {data.user.email}
            </p>
            <p className='device-type'>
                Phone
                <i className="fa-solid fa-mobile-screen-button me-2"></i>
            </p> */}
        </div>
    </>
}
