import React from "react";

export default function NotificationBox({data}) {
  return (
    <>
      <div className="col-lg-12 notification-box">
        <h6 className="notification-title"> {data.text} </h6>
        <p className="notification-body">  {data.text} </p>
        {/* <p className="notification-time">منذ 1 ساعة</p> */}
        <div className="pin-icon">
          <i class="fa-solid fa-thumbtack"></i>
        </div>
        {!data.show && <div className="new-badge">جديد</div>}
      </div>
    </>
  );
}
