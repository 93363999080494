import React, { useContext, useState } from 'react'

import Validator from '../../../Validator'
import { auth } from '../../../Context/AuthStore'
import config from '../../../config'
import axios from 'axios'
import Swal from 'sweetalert2'
import AddPermissions from './AddPermissions'

export default function AddUser() {

    const { userAuth } = useContext(auth)
    const [isLoading, setisLoading] = useState(false)
    const [currentUser, setcurrentUser] = useState(false)
    const [errors, seterrors] = useState({})
    const [newUserData, setnewUserData] = useState({
        name: '',
        username: '',
        parent_phone: '00000000000',
        governorate: "السويس",
        year: 3,
        type_education: 1,
        password: '',
        email: '',
        confirm_password: ''
    })

    // adding new user 
    const addNewUser = (e) => {
        let data = { ...newUserData }
        data[e.target.name] = e.target.value;
        setnewUserData(data)
    }

    // send new user data
    const send = (e) => {
        e.preventDefault();
        seterrors({})

        setisLoading(true)
        let validation = new Validator;
        validation.setKey('name').setValue(newUserData.name).required();
        validation.setKey('username').setValue(newUserData.username).required();
        validation.setKey('password').setValue(newUserData.password).required();
        validation.setKey('confirm_password').setValue(newUserData.confirm_password).required().confirm(newUserData.password);

        if (validation.haveError) {
            seterrors(validation.getErrors())
            setisLoading(false)
            return;
        }

        let data = { ...newUserData }
        delete data.confirm_password

        axios.post(`${config.basURL}dashboard/create-staff`, data, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            setisLoading(false)
            setcurrentUser(data)
            Swal.fire({
                text:' تم الاضافه بنجاح ' ,
                icon: 'success'
            })
            let inputs = document.querySelectorAll(`#${e.target.getAttribute('id')} input`);
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].value = ''
            }
        }).catch(err => {
            setisLoading(false)
            console.log(err)
        })
    }

    return <>
        <div className="container-fluid dashboard-add-user-page">
            <h1>
                اضافة مستخدم
            </h1>
            <form className="add-user mb-3" onSubmit={send} id='add-user-form'>
                <div className="left">
                    <h6>
                        بيانات المستخدم
                    </h6>
                    <div className="inputs">
                        <div>
                            <label htmlFor="name">الاسم </label>
                            <input type="text" id='name' name='name' placeholder='الاسم ' required onChange={addNewUser} />
                            <p className='m-0 text-danger'> {errors['name']} </p>
                        </div>
                        <div>
                            <label htmlFor="email"> البريد الالكتروني</label>
                            <input type="email" id='email' name='email' onChange={addNewUser} />
                            <p className='m-0 text-danger'> {errors['email']} </p>
                        </div>
                        <div>
                            <label htmlFor="username">  أسم المستخدم</label>
                            <input type="text" id='username' name='username' required onChange={addNewUser} />
                            <p className='m-0 text-danger'> {errors['username']} </p>
                        </div>
                        <div>
                            <label htmlFor="password">  الرقم السري</label>
                            <input type="password" id='password' name='password' required onChange={addNewUser} />
                            <p className='m-0 text-danger'> {errors['password']} </p>
                        </div>
                        <div>
                            <label htmlFor="confirm-password"> اعادة كتابة الرقم السري</label>
                            <input type="password" id='confirm-password' name='confirm_password' required onChange={addNewUser} />
                            <p className='m-0 text-danger'> {errors['confirm_password']} </p>
                        </div>
                    </div>
                    <div className='add-btn'>
                        <button disabled={isLoading}>
                            {
                                isLoading ? <i className="fa-solid fa-circle-notch fa-spin"></i> : ' اضافة المستخدم '
                            }
                        </button>
                    </div>
                </div>
            </form>
            <AddPermissions  user={currentUser} reset={setcurrentUser} />
        </div>
    </>
}
