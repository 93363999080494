import React from 'react'
import Error from '../../../Components/Used/Error'

export default function StepTow({ updateUserData, clearInputData, registerData, show, errors }) {
    return <>
        <form className={`container first-step-form form-steps ${show ? '' : 'd-none'}`}>
            <div className='row gap-2'>
                <div className="col-lg-11">
                    <div className={`input-style ${errors['username'] ? 'error' : ''}`}>
                        <input onChange={updateUserData} name='username' type='text' placeholder='رقم الهاتف' value={registerData['username']} maxlength={11} />
                        <div className={`clear-input ${registerData['username'] === '' ? 'd-none' : ''}`} onClick={() => clearInputData('username')}>
                            <i className="fa-solid fa-circle-xmark"></i>
                        </div>
                    </div>
                    <ul className={errors['username'] ? '' : 'd-none'}>
                        {
                            errors['username'] && Object.values(errors['username']).map(error => <li className='text-danger'> {error}  </li>)
                        }
                    </ul>
                </div>

                <div className="col-lg-11">
                    <div className={`input-style ${errors['parent_phone'] ? 'error' : ''}`}>
                        <input onChange={updateUserData} name='parent_phone' type='text' placeholder='رقم هاتف الاب' value={registerData['parent_phone']} maxlength='11' />
                        <div className={`clear-input ${registerData['parent_phone'] === '' ? 'd-none' : ''}`} onClick={() => clearInputData('parent_phone')}>
                            <i className="fa-solid fa-circle-xmark"></i>
                        </div>
                    </div>
                    <ul className={errors['parent_phone'] ? '' : 'd-none'}>
                        {
                            errors['parent_phone'] && Object.values(errors['parent_phone']).map(error => <li className='text-danger'> {error}  </li>)
                        }
                    </ul>
                </div>
            </div>
        </form>
    </>
}
