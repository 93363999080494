import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import config from '../../../config'
import { auth } from '../../../Context/AuthStore'
import Placholder from '../../../Components/Used/Placeholder'
import NotificationBox from "../../../Components/Profile/NotificationBox";
import Placeholder from '../../../Components/Used/Placeholder'

export default function Notification() {

  const {userAuth} = useContext(auth)

  const [notifications, setnotifications] = useState(false)
  const [currentPage, setcurrentPage] = useState(1)
  const [haveNext, sethaveNext] = useState(false)

  const next = () => {
    let page = currentPage
    if(haveNext){
      page += 1
      sethaveNext(page)
    }
  }
  const prev = () => {
    let page = currentPage;
    if(page > 1) {
      page -= 1 ;
      setcurrentPage(page)
    }
  }

  const showNotification = () => {
    axios.put(`${config.basURL}notification/show-notificatio` , {} , {
      headers: {
        'Authorization': config.ApiKey ,
        'auth' : `Bearer ${userAuth}`
      }
    }).then(res => {}).catch(err => {
      console.log(err)
    })
  }

  const getNotifications = () => {
    axios.get(`${config.basURL}notification/notification?page=${currentPage}` , {
      headers: {
        'Authorization': config.ApiKey ,
        'auth' : `Bearer ${userAuth}`
      }
    }).then(res => {
      // console.log(res.data)
      setnotifications(res.data.results)
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(()=>{
    getNotifications()
  } , [])

  return (
    <>
      <div className="container notification-page">
        <div className="row">
          {
            notifications ? (notifications.length > 0 ? notifications.map((noti , index) => <NotificationBox key={index} data={noti}/>) : <p className='text-center w-100 py-2'> لا يوجد اشعارات </p>) : <Placeholder />
          }
        </div>
        <div className="next-prev">
          <button className='prev' onClick={prev} disabled={currentPage > 1 ? false : true}>
            السابق
          </button>
          <button className='next' onClick={next} disabled={haveNext ? false : true}>
            التالي
          </button>
        </div>
      </div>
    </>
  );
}
