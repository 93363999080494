import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import config from '../config'
import { auth } from '../Context/AuthStore'
import Placeholder from '../Components/Used/Placeholder'
import { useNavigate, useParams } from 'react-router-dom'
import jwtEncode from 'jwt-encode';
import useQueryParams from '../Hooks/useQueryParams'

export default function SessionProvider(props) {

    const secret = 'k34D23GbR2s3f2Jg4JfR2MfS4Dl3KlYwD6YlLzQJmNk='; // Replace with your actual secret key

    const { course_id } = useParams()
    let { unit, lesson } = useQueryParams();

    const [done, setdone] = useState(false)

    const { userAuth } = useContext(auth)
    const navigate = useNavigate()

    const [userData, setuserData] = useState({
        "name": "mohamed hossam",
        "userName": "mhamee04",
        "id": "17",
        "mobile": "+201145332511",
        "clientId": "4", 
        "email": "mail@mail.com",
        "contentId": "f878b9e6-eadd-408e-abef-6f2217e974ef", 
        'otherContentId': '673eaf04f41e40a79799b740d5f2815a',
        "pullZone": "GuardXpert", 
        "vlib": "289252",
        "platform": "windows",
        "ip": "192.168.1.1",
        "browser": "chrome",
        "model": "pop os"
    })

    const craeteSession = () => {
        const token = jwtEncode(userData, secret, { algorithm: 'HS256' });
        sessionStorage.setItem('otherData', token)
        setdone(true)
        // navigate(`/my-courses/${course_id}/watch?unit=${unit}&lesson=${lesson}`)
    }

    useEffect(() => {
        craeteSession()
    }, [])

    return <>
        {
            done ? props.children : <div className="subscription-gourd">
                <Placeholder />
            </div>
        }
    </>
}