import React from 'react'

export default function NotFound() {

  return <>
  <div className="notfound-page">
    <h1>notfound 404 </h1>
  </div>
  </>
}
