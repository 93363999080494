import React from 'react'

export default function TermsConditions() {
  return <>
    <div className="privacy-policy">
      <div className="top">
        <h1> البنود و الشروط </h1>
      </div>
      <div className="container">
        <h5>
          البنود والشروط
        </h5>
        <p>
          مرحبًا بك في منصة د. محمود الغرباوي! توضح هذه الشروط والأحكام القواعد واللوائح الخاصة باستخدام منصة د. محمود الغرباوي . بدخولك إلى هذا الموقع ، نفترض أنك تقبل هذه الشروط والأحكام. لا تستمر في استخدام منصة د. محمود الغرباوي إذا كنت لا توافق على أخذ جميع الشروط والأحكام المذكورة في هذه الصفحة.
        </p>
        <h5>
          ملفات تعريف الارتباط
        </h5>
        <p>
          نحن نستخدم استخدام ملفات تعريف الارتباط. عن طريق الوصول إلى منصة د. محمود الغرباوي ، لقد وافقت على استخدام ملفات تعريف الارتباط بالاتفاق مع سياسة خصوصية منصة د. محمود الغرباوي. تستخدم معظم مواقع الويب التفاعلية ملفات تعريف الارتباط للسماح لنا باسترداد تفاصيل المستخدم لكل زيارة. يستخدم موقعنا ملفات تعريف الارتباط لتمكين وظائف مناطق معينة لتسهيل زيارة الأشخاص لموقعنا. قد يستخدم بعض الشركاء التابعين / المعلنين أيضًا ملفات تعريف الارتباط.
        </p>
        <h5>
          الترخيص
        </h5>
        <p>
          ما لم يذكر خلاف ذلك ، د. محمود الغرباوي و / أو المرخصون التابعون لها يمتلكون حقوق الملكية الفكرية لجميع المواد الموجودة على د. محمود الغرباوي. جميع حقوق الملكية الفكرية محفوظة. يمكنك الوصول إلى هذا من د. محمود الغرباوي لاستخدامك الشخصي يخضع للقيود المنصوص عليها في هذه الشروط والأحكام. يحظر عليك:
        </p>
        <ul>
          <li>إعادة نشر مادة من د. محمود الغرباوي</li>
          <li>
            بيع أو تأجير أو ترخيص من الباطن لمواد من د. محمود الغرباوي
          </li>
          <li>
            إعادة إنتاج أو نسخ أو نسخ المواد من د. محمود الغرباوي
          </li>
          <li>
            إعادة توزيع المحتوى من د. محمود الغرباوي
          </li>
        </ul>
        <p>
          توفر أجزاء من هذا الموقع فرصة للمستخدمين لنشر وتبادل الآراء والمعلومات في مناطق معينة من الموقع. د. لا تقوم مجدي فودة بتصفية أو تحرير أو نشر أو مراجعة التعليقات قبل وجودها على الموقع. التعليقات لا تعكس آراء وآراء د. محمود الغرباوي ووكلائها و / أو الشركات التابعة لها. تعكس التعليقات وجهات نظر وآراء الشخص الذي ينشر وجهات نظره وآرائه. إلى الحد الذي تسمح به القوانين المعمول بها ، د. محمود الغرباوي ليست مسؤولة عن التعليقات أو عن أي مسؤولية أو أضرار أو نفقات ناتجة و / أو تكبدتها نتيجة لأي استخدام و / أو نشر و / أو ظهور التعليقات على هذا الموقع. تحتفظ منصة د. محمود الغرباوي بالحق في مراقبة جميع التعليقات وإزالة أي تعليقات يمكن اعتبارها غير لائقة أو مسيئة أو تسبب خرقًا لهذه الشروط والأحكام. أنت تضمن وتقر بما يلي:
        </p>
        <ul>
          <li>
            يحق لك نشر التعليقات على موقعنا ولديك جميع التراخيص والموافقات اللازمة للقيام بذلك ؛
          </li>
          <li>
            لا تنتهك التعليقات أي حق من حقوق الملكية الفكرية ، بما في ذلك على سبيل المثال لا الحصر حقوق الطبع والنشر أو براءة الاختراع أو العلامة التجارية لأي طرف ثالث ؛
          </li>
          <li>
            لا تحتوي التعليقات على أي مواد تشهيرية أو تشهيرية أو مسيئة أو غير لائقة أو غير قانونية بأي شكل من الأشكال مما يعد انتهاكًا للخصوصية
          </li>
          <li>
            لن يتم استخدام التعليقات لالتماس أو الترويج لأنشطة تجارية أو مخصصة أو حالية أو نشاط غير قانوني.
          </li>
        </ul>
        <p>
          أنت بموجب هذا تمنح د. محمود الغرباوي هو ترخيص غير حصري لاستخدام وإعادة إنتاج وتحرير وتفويض الآخرين لاستخدام وإعادة إنتاج وتحرير أي من تعليقاتك في أي وجميع الأشكال أو الأشكال أو الوسائط.
        </p>
      </div>
    </div>
  </>
}
