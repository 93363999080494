import fawry from '../assets/blue.png'
import React, { useEffect, useState, useContext, useRef } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import config from '../config';
import { auth } from '../Context/AuthStore';

export default function Invoice() {

    const navigate = useNavigate()

    const { code, id } = useParams()

    const { userAuth } = useContext(auth)

    const [courseDetails, setcourseDetails] = useState(false)

    const invoiceDetails = () => {
        axios.get(`${config.basURL}subscription/invoice-details/${id}`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            console.log(res.data)
            setcourseDetails(res.data)
        }).catch(err => {
            console.log(err)
        })
    }

    function getHumanDate(d) {
        const date = new Date(d);
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', };
        return date.toLocaleDateString('ar', options);
    }

    useEffect(() => {
        if (userAuth) {
            invoiceDetails()
        }
    }, [userAuth])

    return <>
        <div className="invoice-container">
            <div className="invoice">
                <div className="logo text-center">
                    <img src={fawry} alt="" />
                </div>
                <h1 className='code rounded'>
                    {
                        code ? code : <i className="fa-solid fa-spinner fa-spin"></i>
                    }
                </h1>
                <h4 className='py-2 text-center'>
                    تفاصيل الفاتوره :
                </h4>
                <ul className='d-flex flex-column mb-4 align-items-center'>
                    {
                        courseDetails ? <>
                            <li> اسم الكورس : {courseDetails.course_name} ( {courseDetails.course_year} ) </li>
                            <li> سعر الكورس : {courseDetails.course_price} </li>
                            <li> العدد : 1 </li>
                            <li className='fw-bold'> اجمالي المبلغ : {courseDetails.amount} جنيه </li>
                        </> : <li className='loading-invoice-details'> <i class="fa-solid fa-spinner fa-spin-pulse"></i> </li>
                    }
                </ul>
                <p className='text-center' > تنتهي صلاحيه الفاتوره في <span className='fw-bold'>{courseDetails && getHumanDate(courseDetails.payment_expiry)}</span> </p>
                <p className='text-center'> روح لاقرب مكنه فوري عشان تدفع بالكود  </p>
                <div className='text-center'>
                    <button className='btn btn-primary' onClick={() => navigate('/user-profile/invoices')}> الذهاب الي الفواتير السابقه </button>
                </div>
            </div>
        </div>
    </>
}
