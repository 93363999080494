import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import config from '../../config'
import axios from 'axios'
import { auth } from '../../Context/AuthStore'
import Swal from 'sweetalert2'

export default function CourseBox({ data }) {

    const { userAuth } = useContext(auth);
    const { showSwal } = useOutletContext();
    const navigate = useNavigate()

    // const [isActive, setISActive] = useState(false)

    const subscripe = () => {

        if (userAuth === null) {
            showSwal(' انت مسجلتش دخول هتشترك في الكورس ازاي ', 'error', '/auth')
        } else {

            axios.post(`${config.basURL}subscription/subscribe-to-course`, {
                course_id: data.id
            }, {
                headers: {
                    'Authorization': config.ApiKey,
                    'auth': `Bearer ${userAuth}`,
                }
            }).then((res) => {

                if(res.data.active) {

                    navigate(`/my-course-preview/${data.id}`)

                }else {
                    navigate(`/create-invoice/${data.id}`)
                }

                // console.log(res.data)
                // if (res.data.inboard) {
                //     if(data.free){
                //         navigate(`/my-course-preview/${data.id}`)
                //     }else {
                //         if(res.data.active) {
                //             showSwal(' انت دلوقتي مشترك في الكورس  ', 'success', `/my-course-preview/${data.id}`)
                //         }else {
                //             navigate(`/create-invoice/${data.id}`)
                //         }
                //     }
                // } else {
                //     if(data.free){
                //         navigate(`/my-course-preview/${data.id}`)
                //     }else {
                //         if(res.data.active) {
                //             showSwal(' انت دلوقتي مشترك في الكورس  ', 'success', `/my-course-preview/${data.id}`)
                //         }else {
                //             navigate(`/create-invoice/${data.id}`)
                //         }
                //     }
                // }

            }).catch((err) => {
                console.log(err)
            })
        }
    }                        

    function getHumanDate(d) {
        const date = new Date(d);
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('ar', options);
    }

    useEffect(() => {
        console.log(data)
    } , [])

    return <>

        <div className='col-lg-3 course-box'>
            <div className='course-image'>
                <img src={data.cover} className='w-100 h-100' />
                {/* <div className='add-course-icon'>
                    <i className="fa-solid fa-plus"></i>
                </div> */}
            </div>
            <div className='course-title'>
                <h5 className='title fw-bold'>
                    {data.name}
                </h5>
                <p className='p-0 m-0'>
                    {data.about}
                </p>
            </div>
            <div className='course-time-price'>
                <div className='course-time'>
                    <div className='time'>
                        <i className="fa-regular fa-clock"></i>
                        <p className='m-0 p-0'>
                            {
                                getHumanDate(data.created)
                            }
                        </p>
                    </div>
                </div>
                <div className='price'>
                    {
                        data.free ? '' : <><span>{data.price}</span>جـ</>
                    }
                </div>
            </div>
            <div className='go-to-course'>
                <Link to={`/course-preview/${data.id}`} className='enter'>
                    الدخول للكورس
                </Link>
                <button onClick={subscripe} className='enroll'>
                    أشترك الأن
                </button>
            </div>
        </div>
    </>
}
